import './styles/App.css';
import swal from "sweetalert";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import {Col, Row, Container} from "react-bootstrap";
import DotNFTMinter from './utils/DotNFTMinter.json';

const OPENSEA_LINK = 'https://testnets.opensea.io/collection/dotnft33';
const CONTRACT_ADDRESS = "0xb1138f775fB2E4D219ccae139bfAfD6689B0B86D"; // process.env.CONTRACT_ADDRESS

const App = () => {

  const [currentAccount, setCurrentAccount] = useState("");
  const [rinkeby, setRinkeby] = useState(false);
  const [maxNFT, setMaxNFT] = useState(0);
  const [mintedNFT, setMintedNFT] = useState(0);
  const [isMining, setIsMining] = useState(false);
  const [isConnectedToWeb3, setIsConnectedToWeb3] = useState(false);
  
  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    // check metamask
    if (!ethereum) {
      swal("Oops!", "Make sure you have metamask!", "error");
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }
    
    // check chain
    let chainId = await ethereum.request({method: "eth_chainId"});
    console.log("Connected to chain " + chainId);
    // String, hex code of the chainId of the Rinkebey test network
    const rinkebyChainId = "0x4";
    if (chainId !== rinkebyChainId) {
      setRinkeby(false);
      swal("Oops!","You are not connected to the Rinkeby Test Network!", "error");
    } else {
      setRinkeby(true);
    }
    
    // check
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length !== 0) {
      setIsConnectedToWeb3(true);
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
      
      // Setup listener! This is for the case where a user comes to our site
      // and ALREADY had their wallet connected + authorized.
      setupEventListener();
      fetchNFTMintingInfo();
    } else {
      console.log("No authorized account found")
    }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        swal("Oops!","Get MetaMask!", "error");
        return;
      }
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
      // Setup listener! This is for the case where a user comes to our site
      // and connected their wallet for the first time.
      setupEventListener() 
    } catch (error) {
      console.log(error)
    }
  }
  
  // Setup our listener.
  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;
      
      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, DotNFTMinter.abi, signer);
        
        // THIS IS THE MAGIC SAUCE.
        // This will essentially "capture" our event when our contract throws it.
        // If you're familiar with webhooks, it's very similar to that!
        connectedContract.on("DotNFTMinterMinted", (from, tokenId) => {
          console.log(from, tokenId.toNumber())			
          swal({
            title: "Excellent!",
            icon: "success",
            text: "We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea. Here's the link:",
            content: {
              element: "a",
              attributes: {
                className: "cta-button connect-wallet-button",
                text: "Go to Opensea!",
                href: `https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`,
                target: '_blank'
              }
            }
          });		  
        });
        console.log("Setup event listener!")
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchNFTMintingInfo = async () => {
    try {
      const { ethereum } = window;
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS,	DotNFTMinter.abi, signer);
        
        console.log("collecting NFT minting info");
        let maxNFT = await connectedContract.getMaxNFTsMinted();
        let mintedNFT = await connectedContract.getTotalNFTsMintedSoFar();
        
        setMaxNFT(maxNFT.toNumber());
        setMintedNFT(mintedNFT.toNumber());
      } else {
        swal("Oops!", "Ethereum object doesn't exist!", "error");
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const askContractToMintNft = async () => {
    try {
      const { ethereum } = window;
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, DotNFTMinter.abi, signer);
        
        console.log("Going to pop wallet now to pay gas...")
        let nftTxn = await connectedContract.makeAnEpicNFT();
        
        swal("Minting...", "..please wait.",{button: false});
        console.log("Mining...please wait.")
        
        await nftTxn.wait();
        console.log(nftTxn);
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
      } else {
        swal("Oops!", "Ethereum object doesn't exist!", "error");
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  
  useEffect(() => {
    checkIfWalletIsConnected();
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfWalletIsConnected])
  
  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">
      Connect to Wallet
    </button>
  );
  
  const renderMintUI = () => (
    <button onClick={askContractToMintNft} className="cta-button connect-wallet-button">
		Mint NFT
    </button>
  )
  
  return (
	<Container className="App">
		<Row>
			<Col xs={6} md={4}>
				<h1 className="header">Welcome To React-Bootstrap</h1>
				<p className="sub-text">
				  Each unique. Each beautiful. Discover your NFT today.
				</p>
				<p className="sub-text">
					Check out the collection on{" "}
					<a target="_blank" rel="noreferrer" href={OPENSEA_LINK}>OpenSea</a>{" "}(rinkebytestnet)
				</p>
				
				{maxNFT !== 0 && (
					<p className="sub-text">
						{mintedNFT} of {maxNFT} NFTs minted.
					</p>
				)}
				{maxNFT === 0 && maxNFT === 0 && isConnectedToWeb3 === true && rinkeby && (
					<div className="sub-text">
					<p className="sub-text inline">Fetching minting stats</p>
					<div className="lds-ripple ">
						<div></div>
  						<div></div>
					</div>
					</div>
				)}
				{currentAccount === "" ? renderNotConnectedContainer() : renderMintUI()}
			</Col>
		</Row>	
	</Container>
  );
};

export default App;
